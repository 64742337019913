import Link from "next/link";

const DaoBlock = () => {
  return (
    <>
      <div className="mb-12 lg:w-1/2">
        <div className="rounded-2.5xl bg-white p-12 dark:bg-jacarta-700">
        <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white transition transition-colors">
            DO IT YOURSELF
          </span>
          <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
            Sign up, get your brand or cause and campaign approved and you’re
            good to go! Your Impact awaits!
          </p>
          <a
            href="#"
            className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
          >
            Find Out More
          </a>
        </div>
      </div>
      {/* End A global distributed community */}

      <div className="mb-12 lg:w-1/2">
        <div className="h-full rounded-2.5xl bg-white p-12 dark:bg-jacarta-700">
        <span className="mb-2 mt-1 block font-display text-xl font-medium group-hover:text-accent dark:text-white transition transition-colors">
            DONE FOR YOU
          </span>
          <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
            Would you rather have it all done for you? Get in touch, and if it’s
            the right option for you, we’ll take care of it all!
          </p>
          <Link
            href="/contact"
            className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
          >
            Get In Touch
          </Link>
        </div>
      </div>
      {/* End What’s your DAO Idea?
Let’s chat. */}
    </>
  );
};

export default DaoBlock;
